import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import * as Page from "./pages";
import { settings } from "./settings/settings";

import React, { useCallback, useEffect } from "react";
import { ModalProvider } from "./context/Modal/modal.context";
function App() {
  const location = useLocation();
  let root = document.documentElement;

  const appHeight = useCallback(() => {
    var vh = window.innerHeight * 0.01;
    root.style.setProperty("--vh", `${vh}px`);
  }, [root.style]);

  useEffect(() => {
    appHeight();
    window.addEventListener("resize", appHeight);
    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, [appHeight]);

  const routes = settings.routes.map((el) => {
    const component =
      el.page_attribute.view &&
      React.createElement(Page[el.page_attribute.view], el);
    return (
      <Route
        index={el.page_attribute.index}
        path={el.path}
        element={component}
        key={el.name}
      >
        {el.sub_path && <Route path={el.sub_path} element={component} />}
      </Route>
    );
  });
  return (
    <ModalProvider>
      <div className="App">
        {/* <AnimatePresence> */}
        <Routes location={location} key={location.pathname}>
          <Route element={<Page.Layout />}>
            {routes}
            <Route path="*" element={<Page.NotFound />} />
            <Route path="/search/:term" element={<Page.Search />} />
          </Route>
        </Routes>
        {/* </AnimatePresence> */}
      </div>
    </ModalProvider>
  );
}

export default App;
