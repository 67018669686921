import React from "react";
import styles from "./square.styles";
import defaultImage from "../../../assets/media/images/default.png";
import SquarePlusButton from "./SquarePlusButton.component";

const SquareImage = (props) => {
  const bg =
    props.background !== ""
      ? require(`/src/assets/media/images/${props.background}`)
      : "var(--primary_color)";

  //   backgroundImage: 'url("/static/media/image_1.6d1fafeadc11008a5780.jpeg")',
  // backgroundPosition: 'center',
  // backgroundSize: 'cover'
  return (
    <div
      className="h-100 w-100 square_image d-flex justify-content-center align-items-center"
      style={styles.background(props.background === "", bg)}
    >
      {props.background === "" && (
        <img style={styles.defaultImage} src={defaultImage} alt="" />
      )}
      {props.action?.type === 2 && (
        <SquarePlusButton
          image={require(`/src/assets/media/images/${props.background}`)}
          {...props.action}
        />
      )}
    </div>
  );
};

export default SquareImage;
