import React from "react";
import { useNavigate } from "react-router-dom";

const SquareText = (props) => {
  const { title, body, action } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    if (action.type === 1) {
      navigate(action.target);
    } else alert("open modal");
  };

  return (
    <div
      to=""
      className={`d-flex align-items-center h-100 square_text px-3 link ${action.type ? "link" : ""}`}
      onClick={handleClick}
    >
      <p className="title">{title}</p>
      <p className="square-paragraph">{body}</p>
    </div>
  );
};

export default SquareText;
