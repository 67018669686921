import { motion } from "framer-motion";
import useCurrentWidth from "../hooks/useWidth";

const Motion = ({ children }) => {
  //* *FADEIN* */
  // initial={{ opacity: 0 }}
  // animate={{ opacity: 1 }}
  // exit={{ opacity: 0 }}
  // const isPresent = useIsPresent();
  // const fadeIn = {
  //   initial: { opacity: 0 },
  //   animate: {},
  // };

  const width = useCurrentWidth();

  const pageVariants = {
    initial: {
      opacity: 0,
      x: "-100vw",
      scale: 0.8
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1
    },
    out: {
      opacity: 0,
      x: "100vw",
      scale: 1.9
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3
  };
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}

      // initial={{ scaleY: 0 }}
      // animate={{ scaleY: 1 }}
      // exit={{ scaleY: 0 }}
    >
      <div
        className="container-fluid d-flex px-0 px-xl-5 justify-content-center align-items-center motion_div"
        style={{
          height:
            width >= 992
              ? "calc(var(--vh, 1vh) * 100 - 100px)"
              : "calc(var(--vh, 1vh) * 100 - 150px)",

          overflowY: "scroll",
          flexWrap: "wrap"
        }}
      >
        {children}
      </div>
    </motion.div>
  );
};

export default Motion;
