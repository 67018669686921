import { createContext, useState } from "react";
import ModalComponent from "../../components/modal/Modal.component";
import BottomSheetComponent from "../../components/bs/BottomSheet.component";
import useCurrentWidth from "../../hooks/useWidth";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [modalType, setModalType] = useState(1);
  const width = useCurrentWidth();

  const handleClose = () => {
    setIsOpen(false);

    // setModalContent({});
  };
  const handleOpen = () => setIsOpen(true);

  const setContent = (el) => {
    setModalContent(el);
  };

  const resetModal = () => {
    setModalContent({});
  };

  const renderComponent = () => {
    return width >= 992 ? <ModalComponent /> : <BottomSheetComponent />;
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        handleClose,
        handleOpen,
        setContent,
        modalContent,
        resetModal,
        setModalContent,
        modalType,
        setModalType
      }}
    >
      {renderComponent()}
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;
