import React, { useContext } from "react";
import { PlusIcon } from "../../../assets/media/icone";
import { styles } from "./plusButton.styles";
import ModalContext from "../../../context/Modal/modal.context";

const PlusButton = (props) => {
  const { handleOpen, setModalContent, setModalType } =
    useContext(ModalContext);

  const modalContent = { ...props };

  return (
    <div
      onClick={() => {
        setModalType(props.type ? props.type : 1);
        handleOpen();
        setModalContent(modalContent);
      }}
      className="justify-content-center align-items-center d-flex"
      style={{ ...styles.containerStyle, ...props.style }}
    >
      <img src={PlusIcon} alt="plus" />
    </div>
  );
};

export default PlusButton;
