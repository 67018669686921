import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as Menabo from '../../assets/media/images'
import useCurrentWidth from '../../hooks/useWidth';

const MenaboSections = (props) => {

  const width = useCurrentWidth();
  const navigate = useNavigate()

  const getElements = props.content.map((el, index) => {
    const imgName = width < 992 ? Menabo[`Menabo_m_${index+1}`] : Menabo[`Menabo_${index+1}`];
    return (
      <div onClick={() => navigate(el.target)}  key={`${el.target}_${index}`} className='col-12 col-md-4 my-1 p-3' style={{cursor: "pointer"}}>
        <img className='img-fluid' style={{border: "1px solid black"}} src={imgName}/>
      </div>
    )
  })

  return (
    <div className='row'>
        {getElements}
    </div>
  )
}

export default MenaboSections