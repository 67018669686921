import React from "react";
import SquareImageComponent from "./SquareImage.component";
import SquareText from "./SquareText.component";

const Square = (props) => {
  const { content } = props;
  const elements = content.map((el, index) => {
    const elementToRender =
      el.type === "image" ? (
        <SquareImageComponent action={el.action} background={el.src} />
      ) : (
        <SquareText title={el.title} body={el.body} action={el.action} />
      );
    return (
      <div
        key={`${el}_${index}`}
        className={`${el.type}_square section_square`}
      >
        {elementToRender}
      </div>
    );
  });

  return (
    <div className="row mb-3 mb-lg-0">
      <div className="col-xxl-8 m-auto wrapper_square">{elements}</div>
    </div>
  );
};

export default Square;
