import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { getWrapperClasses } from "../../../utils/utils";
import { styles } from "./sectionWrapper.styles";

const SectionWrapper = (props) => {
  const { sections: array_sections, children } = props;

  const location = useLocation();
  const param = useParams();

  const sections = array_sections.map((_, index) => (
    <div
      key={index}
      className={getWrapperClasses(index, location.pathname, array_sections.length, param.name)}
    >
      {React.cloneElement(children[index])}
    </div>
  ));

  return (
    <div style={styles.mainContainer} className="row h-100 w-100 px-2">
      {sections}
    </div>
  );
};

export default SectionWrapper;
