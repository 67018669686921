import React from "react";
import InfoTecniche from "../common/infoTecniche/InfoTecniche.component";

const StructureAzienda = (props) => {
  const { info } = props;
  
  return (
    <div>
      {info.map((el, index) => {
        const { title, body, attributes } = el;

        return (
          <div key={`${el.title}_${index}`}>
            <p style={{ fontFamily: "Play", fontSize: "1.5rem" }}>{title}</p>
            {el.datasheet && <InfoTecniche datasheet={el.datasheet} />}
            <p style={{ color: "white", fontFamily: "Lato" }}>{body}</p>
            {attributes &&
              attributes.map((at, index) => {
                return (
                  <div className="d-flex" key={`${at.key}_${index}`}>
                    <p>
                      {at.key !== "" && (
                        <span
                          style={{
                            fontFamily: "Lato",
                            textTransform: "uppercase",
                            fontWeight: "bold"
                          }}
                        >
                          {at.key}:
                        </span>
                      )}
                      &nbsp;
                      <span style={{ fontFamily: "Lato" }}>{at.value}</span>
                    </p>
                  </div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default StructureAzienda;
