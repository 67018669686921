import * as React from "react";
import { useRef } from "react";
import { motion } from "framer-motion";
import { useDimensions } from "../../hooks/useDimension";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../assets/media/icone";
import useCurrentWidth from "../../hooks/useWidth";
import NavMobile from "../navMobile/NavMobile.component";
import useStateWithCallback from "use-state-with-callback";
import { useContext } from "react";
import ModalContext from "../../context/Modal/modal.context";

const search = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 3 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 60,
      restDelta: 2
    }
  }),
  closed: {
    clipPath: "circle(0px at 0px 0px)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40
    }
  }
};

const Header = () => {
  const { handleClose } = useContext(ModalContext);
  const width = useCurrentWidth();
  const [term, setTerm] = React.useState("");
  const [headerClass, setHeaderClass] = React.useState("header_container");
  const [isOpen, setIsOpen] = useStateWithCallback(false, (currentOpened) => {
    if (currentOpened) {
      setHeaderClass("");
    } else {
      setTimeout(() => {
        setHeaderClass("header_container");
      }, 200);
    }
  });

  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const navigate = useNavigate();
  const logo = require("../../assets/media/images/logo.png");

  return (
    <div
      className={`w-100 ${headerClass}`}
      style={{ height: width >= 992 ? "50px" : "100px" }}
    >
      <div className="d-flex p-3 justify-content-between mx-0 mx-lg-5">
        <div className="col-5 col-lg-1 text-start">
          <img className="img-fluid" src={logo} alt="" />
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIsOpen((prev) => !prev, {});

            // if (isOpen) {
            //   setTimeout(() => {
            //     setHeaderClass("");
            //   }, 400);
            // }
          }}>
          <img
            style={{ width: "30px", height: "30px", cursor: "pointer" }}
            alt="search"
            src={SearchIcon}
          />
          <span className="ms-1 ms-lg-3 search-label mb-3 pe-5 pb-1">cerca prodotto</span>
        </div>
      </div>
      {/* NAVBAR MOBILE */}
      {width < 992 && <NavMobile />}

      <motion.div
        initial={true}
        animate={isOpen ? "open" : "closed"}
        custom={height}
        ref={containerRef}
      >
        <motion.div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column"
          }}
          className="background"
          variants={search}
        >
          <button
            className="p-2 m-4"
            style={{ alignSelf: "flex-end", width: "50px", border: "none" }}
            onClick={() => setIsOpen(false)}
          >
            X
          </button>
          <div
            className="h-100 w-100 d-flex flex-column"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <input
              style={{
                width: "80%",
                color: "#fff",
                background: "rgba(255, 255, 255, 0.1)",
                fontSize: "18px",
                letterSpacing: "4px",
                textTransform: "uppercase",
                textAlign: "center",
                border: "0px",
                margin: "0px",
                padding: "30px",
                outline: "none",
                height: "auto"
              }}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  handleClose()
                  navigate(`/search/${term}`);
                }
              }}
              placeholder="Ricerca prodotti"
              value={term}
              onChange={(e) => setTerm(e.target.value.toLowerCase())}
            ></input>

            <button
              className="mt-3"
              style={{
                textTransform: "uppercase",
                padding: "16px 48px",
                background: "#fff",
                color: "#000"
              }}
              onClick={() => {
                handleClose()
                navigate(`/search/${term}`);
              }}
            >
              Cerca
            </button>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Header;
