import React from "react";
import Motion from "../utils/Motion";
import { settings } from "../settings/settings";
import * as Components from "../components";
import SectionWrapper from "../components/common/sectionWrapper/SectionWrapper.component";
import { useParams } from "react-router-dom";
import WrapperProdotti from "../components/common/prodotti/WrapperProdotti.component";

const Prodotti = (props) => {
  const {
    content: { sections },
    page_attribute
  } = props;

  const param = useParams();

  const ComponentToRender = sections.map((el, index) => {
    if (
      page_attribute.products &&
      param.name &&
      settings.sections[el].name !== "Navbar"
    ) {
      return (
        <WrapperProdotti
          category={param.name}
          key={`${settings.sections[el].name}_${index}`}
        />
      );
    }
    return (
      <React.Fragment key={`${settings.sections[el].name}_${index}`}>
        {React.createElement(
          Components[settings.sections[el].name],
          settings.sections[el].props
        )}
      </React.Fragment>
    );
  });

  return (
    <Motion>
      <SectionWrapper sections={sections}>{ComponentToRender}</SectionWrapper>
    </Motion>
  );
};

export default Prodotti;
