import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon, ArrowRightIcon } from "../../assets/media/icone";
import { settings } from "../../settings/settings";

const Footer = ({ handleSetRoute }) => {
  const location = useLocation();
  const title = location.pathname !== '/' ? location.pathname.substring(1).replace('search/', 'prodotti - ').toUpperCase() : 'CANTINE BARONE'
  const { routes } = settings
  const navigate = useNavigate();

  return (
    <div className="d-flex justify-content-center align-items-center">

      {/* vai a prima pagina */}
      <div className="d-flex me-3" onClick={() => { navigate(routes[0].path) }}>
        <img
          alt="arrow_right"
          src={ArrowLeftIcon}
          style={{
            width: "10px",
            cursor: location.pathname === routes[0].path ? "default" : "pointer",
            opacity: location.pathname === routes[0].path ? "0.3" : "unset"
          }} />
        <img
          alt="arrow_right"
          src={ArrowLeftIcon}
          style={{
            width: "10px",
            cursor: location.pathname === routes[0].path ? "default" : "pointer",
            opacity: location.pathname === routes[0].path ? "0.3" : "unset"
          }} />
      </div>

      {/* paginatore */}
      <img
        onClick={() => {
          handleSetRoute(-1);
        }}
        alt="arrow_left" src={ArrowLeftIcon}
        style={{
          width: "10px",
          cursor: location.pathname === routes[0].path ? "default" : "pointer",
          opacity: location.pathname === routes[0].path ? "0.3" : "unset"
        }} />
      <p className="m-0" style={{ width: "200px", fontFamily: 'Lato', color: 'var(--primary_color)', fontWeight: 'bold' }}>{title}</p>
      <img
        alt="arrow_right"
        onClick={() => handleSetRoute(1)} src={ArrowRightIcon}
        style={{
          width: "10px",
          cursor: location.pathname === routes[routes.length - 1].path ? "default" : "pointer",
          opacity: location.pathname === routes[routes.length - 1].path ? "0.3" : "unset"
        }} />

        {/* vai a ultima pagina */}
      <div onClick={() => { navigate(routes[routes.length - 1].path) }} className="d-flex ms-3">
        <img
          alt="arrow_right"
          src={ArrowRightIcon}
          style={{
            width: "10px",
            cursor: location.pathname === routes[routes.length - 1].path ? "default" : "pointer",
            opacity: location.pathname === routes[routes.length - 1].path ? "0.3" : "unset"
          }} />
        <img
          alt="arrow_right"
          src={ArrowRightIcon}
          style={{
            width: "10px",
            cursor: location.pathname === routes[routes.length - 1].path ? "default" : "pointer",
            opacity: location.pathname === routes[routes.length - 1].path ? "0.3" : "unset"
          }} />
      </div>
    </div>
  );
};

export default Footer;
