import React from "react";

const Text = (props) => {
  const content = props.content.map((el) => (
    <p key={el.id} style={props.option} className={`${el.type}`}>
      {el.text}
    </p>
  ));
  return <div className="row">
    <div className="col-12 col-lg-8 m-auto mt-3 mt-lg-0 text-center text-lg-start">
      {content}
    </div>
  </div>;
};

export default Text;
