import React from "react";
import { useParams } from "react-router-dom";
import Prodotto from "../components/common/prodotti/Prodotto.component";
import { settings } from "../settings/settings";
import Motion from "../utils/Motion";

const Search = () => {
  const param = useParams();

  const results = settings.products.filter(
    (el) =>
      el.field1.toLowerCase().includes(param.term) ||
      el.field2.toLowerCase().includes(param.term) ||
      el.category.toLowerCase().includes(param.term) ||
      el.description.toLowerCase().includes(param.term)
  );

  if (!results || results.length === 0) {
    return (
      <Motion>
        <h3>Nessun risultato trovato per i parametri di ricerca inseriti</h3>
      </Motion>
    );
  }
  return (
    <Motion>
      <div className="row w-100">
        {results.map((el) => (
          <div
          key={el.id}
          className="col-12 col-lg-3 mb-3 d-lg-block d-flex justify-content-center">
            <Prodotto
            style={{aspectRatio:"1/1"}}
              {...el}
            />
          </div>
        ))}
      </div>
    </Motion>
  );
};

export default Search;
