export const styles = {
  containerStyle: {
    border: `1px solid var(--primary_color)`,
    position: "relative",
    display: "flex"
  },
  image: { width: "40%", objectFit: "contain" },
  infoContainer: { width: "60%" },
  fields: (width) => {
    return {
      fontFamily: 'Lato',
      fontSize: width >= 992 ? "0.8rem" : "0.6rem",
      color: "var(--primary_color)",
      textTransform: "uppercase"
    };
  }
};
