export const styles = {
  background: (isBlank, val) => {
    const objStyle = {
      position: "relative",
      color: "white",
      fontSize: "28px"
    };
    return isBlank
      ? {
          ...objStyle,
          backgroundColor: val
        }
      : {
          ...objStyle,
          backgroundImage: `url(${val})`
        };
  },
  defaultImage: {
    position: "absolute",
    right: "10px",
    bottom: "10px",
    width: "80px"
  },
  squareButton: {
    backgroundColor: "rgba(0,0,0,0.5)"
  },
  plusContainer: {
    color: "var(--primary_color)"
  },
  plusButton: {
    lineHeight: "30px",
    width: "30px",
    height: "30px",
    border: "1px solid white",
    cursor: "pointer"
  }
};

export default styles;
