import React from "react";
import Motion from "../utils/Motion";
import * as Components from "../components";
import { settings } from "../settings/settings";
import SectionWrapper from "../components/common/sectionWrapper/SectionWrapper.component";

const Azienda = (props) => {
  const {
    content: { sections }
  } = props;

  const ComponentToRender = sections.map((el, index) => {
    return (
      <React.Fragment key={`${settings.sections[el].name}_${index}`}>
        {React.createElement(
          Components[settings.sections[el].name],
          settings.sections[el].props
        )}
      </React.Fragment>
    );
  });

  return (
    <Motion>
      <SectionWrapper sections={sections}>{ComponentToRender}</SectionWrapper>
    </Motion>
  );
};

export default Azienda;
