import React from "react";
import * as Icon from "../../assets/media/icone/index";
import { settings } from "../../settings/settings";
import NavLinkComponent from "../../components/common/navLink/NavLink.component";

const NavMobile = () => {
  const links = settings.routes.map((el) => (
    <NavLinkComponent key={el.name} el={el}>
      <img
        src={Icon[el.icon]}
        alt={el.name}
        className="img-fluid"
        style={{ width: "25px" }}
      />
    </NavLinkComponent>
  ));
  return (
    <div
      className="w-100 d-flex justify-content-around align-items-center"
      style={{
        height: "50px",
        background: "var(--color_sidebar)",
      }}
    >
      {links}
    </div>
  );
};

export default NavMobile;
