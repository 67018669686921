import React from "react";

// {
//   "name": "Pdf",
//   "icon": "PdfIcon",
//   "path": "/pdf",
//   "page_attribute": {
//     "view": "Pdf",
//     "index": false
//   },
//   "content": {
//     "num_section": 1,
//     "sections": [3]
//   }
// },

const InfoTecniche = (props) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)"
      }}
    >
      {props.datasheet.map((el, index) => {
        return (
          <div key={`${el}_${index}`} style={{ flexWrap: "wrap" }}>
            <p>
              <span
                className="me-2"
                style={{ fontSize: "2.5rem", fontFamily: "Play" }}
              >
                {el.key}
              </span>
              <br/>
              <span style={{ color: "white", fontFamily: "Lato" }}>
                {el.value}
              </span>
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default InfoTecniche;
