import React from "react";
import Motion from "../utils/Motion";

function Pdf() {
  return (
    <Motion>
      <h1>Pdf</h1>
      Pariatur ea voluptate est esse exercitation dolor elit ut cupidatat quis.
      Consequat tempor est excepteur minim magna voluptate et irure nisi aute
      pariatur sit minim. Ut qui aliquip et proident nostrud. Officia occaecat
      id cillum sint aliquip sunt officia id irure laboris occaecat. Quis dolor
      aliqua cupidatat dolore nisi irure ea id consequat eu. Veniam commodo
      pariatur aliqua velit culpa aliquip laboris in cillum exercitation
      exercitation incididunt. Velit amet velit ea dolor minim officia dolore
      deserunt ad in incididunt proident amet.
    </Motion>
  );
}

export default Pdf;
