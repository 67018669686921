import React from "react";
import { NavLink } from "react-router-dom";
import useCurrentWidth from "../../../hooks/useWidth";
const NavLinkComponent = (props) => {
  const width = useCurrentWidth();
  const { el, children } = props;
  const elementToDownload = width < 992 ? "media/pdf/mobile.pdf" : "media/pdf/web.pdf";

  if (!el.page_attribute.view && el.page_attribute.type === "pdf") {
    return (
      <div className="sidebar-link">
        <a href={elementToDownload} download>
          {children}
        </a>
      </div>
    );
  }
  return (
    <>
      {el.page_attribute.line_break &&
        (width > 992 ? (
          <hr className="m-auto" style={{ width: "80%" }} />
        ) : (
          <div
            style={{
              width: "1px",
              backgroundColor: "var(--primary_color)",
              height: "80%",
            }}
          ></div>
        ))}
      <NavLink
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "activeLink" : ""
        }
        exact={"true"}
        to={el.path}
      >
        <div className="sidebar-link">
          {children}
        </div>
      </NavLink>
    </>
  );
};

export default NavLinkComponent;
