import { useContext } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import ModalContext from "../../context/Modal/modal.context";
import BodyHandle from "../bodyHandle/BodyHandle.component";

const BottomSheetComponent = () => {
  const { isOpen, handleClose } = useContext(ModalContext);

  return (
    <BottomSheet
      maxHeight={window.innerHeight - 50}
      snapPoints={({ minHeight, maxHeight }) => [minHeight, maxHeight / 0.6]}
      onDismiss={handleClose}
      open={isOpen}
      blocking={false}
    >
      <BodyHandle />
    </BottomSheet>
  );
};

export default BottomSheetComponent;
