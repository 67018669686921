import React from "react";
import { settings } from "../../../settings/settings";
import Prodotto from "./Prodotto.component";

const WrapperProdotti = (props) => {
  const productsToRender = settings.products
    .filter((el) => el.category === props.category)
    .map((el) => <Prodotto key={el.id} {...el} />);
  return <div className=" wrapper_prodotti">{productsToRender}</div>;
};

export default WrapperProdotti;
