import React from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = (props) => {
  const { content, option } = props;
  const location = useLocation();

  const link = content.map(
    (el) =>
      el.target !== location.pathname && (
        <li key={el.id}>
          <Link to={`${el.target}`}>{el.label}</Link>
        </li>
      )
  );
  return (
    <ul className="custom_navbar" style={option}>
      {link}
    </ul>
  );
};

export default Navbar;
