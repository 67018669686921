export const getWrapperClasses = (index, path, numSections, param) => {

    let col = numSections === 1
        ? "col-lg-8 col-xl-8"
        : `col-lg-${Math.ceil(12 / numSections)}`;

    if (path.includes("/prodotti") && param?.name !== undefined) {
        if (index === 0)
            col = 'col-lg-7'
        col = "col-lg-5"
    }

    if (path === '/')
        col = 'col-12 '

    return `${col} m-auto px-4`

}