import React from "react";
import styles from "./square.styles";
import PlusButton from "../plusButton/PlusButton.component";

const SquarePlusButton = (props) => {
  return (
    <div
      style={styles.squareButton}
      className="d-flex w-100 h-100 flex-column align-items-center justify-content-center"
    >
      <p className="m-0">{props.label}</p>
      <PlusButton
        type={2}
        image={props.image}
        product={props.content}
        style={styles.plusButton}
      />
    </div>
  );
};

export default SquarePlusButton;
