var request = new XMLHttpRequest();

const getSetting = () => {
  try {
    request.open(
      "GET",
      window.location.origin + "/settings/settings.json?v=",
      false
    );

    request.send(null);
    return JSON.parse(request.responseText);
  } catch (error) {
    console.log(error);
  }
};

export const settings = getSetting();
