import React from "react";

const Video = (props) => {

  const { video:{content} } = props

  return <video controls={true} playsInline autoPlay muted loop className="w-100">
      <source src={`media/video/${content.src}`} />
    </video>
};

export default Video;
