import React from "react";
import useCurrentWidth from "../../../hooks/useWidth";
import PlusButton from "../plusButton/PlusButton.component";
import { styles } from "./prodotti.styles";

const Prodotto = (props) => {
  const width = useCurrentWidth();

  return (
    <div
    className="p-3"
      style={{
        ...props.style,
        ...styles.containerStyle
      }}
    >
      <img
        style={styles.image}
        src={require(`/src/assets/media/images/${props.id}.png`)}
        alt={props.field1}
      />
      <div
        className="text-end pe-2 d-flex flex-column align-items-end"
        style={styles.infoContainer}
      >
        <p style={styles.fields(width)}>
          {props.field1}
          <br />
          {props.field2}
        </p>
        <PlusButton
          image={require(`/src/assets/media/images/${props.id}.png`)}
          product={props}
        />
      </div>
    </div>
  );
};

export default Prodotto;
