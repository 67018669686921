import React, { useContext, useEffect, useState } from "react";

// import styles from "./sidebar.styles";
import { settings } from "../../settings/settings";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import Header from "../header/Header";
import useCurrentWidth from "../../hooks/useWidth";
import * as Icon from "../../assets/media/icone";
import Footer from "../footer/Footer";
import ModalContext from "../../context/Modal/modal.context";
import NavLinkComponent from "../../components/common/navLink/NavLink.component";

const SideBar = () => {
  const width = useCurrentWidth();
  const [direction, setDirection] = useState(null);
  const { isOpen } = useContext(ModalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const routes = settings.routes.filter((el) => el.page_attribute.view);

  var xDown = null;
  var yDown = null;

  function getTouches(evt) {
    return evt.touches || evt.originalEvent.touches;
  }
  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }
  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }
    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;
    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (xDiff > 0) setDirection(1);
      else setDirection(-1);
    }
    xDown = null;
    yDown = null;
  }
  function handleTouchEnd() {
    if (direction && !isOpen) {
      handleSetRoute(direction);
      setDirection(null);
    }
  }

  const handleSetRoute = (dir = 1) => {
    for (let index = 0; index < routes.length; index++) {
      let arrayPath = location?.pathname?.substring(1).split("/");

      if (routes[index].path === `/${arrayPath[0]}`) {
        if (routes[index + dir]) {
          if (arrayPath.length > 1) navigate(`/${arrayPath[0]}`);
          else {
            navigate(routes[index + dir].path);
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("touchstart", handleTouchStart, false);
    document.addEventListener("touchmove", handleTouchMove, false);
    document.addEventListener("touchend", handleTouchEnd, false);

    return () => {
      document.removeEventListener("touchstart", handleTouchStart, false);
      document.removeEventListener("touchmove", handleTouchMove, false);
      document.removeEventListener("touchend", handleTouchEnd, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [direction]);

  const menuItems = settings.routes.map((el) => {
    return (
      <NavLinkComponent el={el} key={el.name}>
        <CDBSidebarMenuItem
          style={{ textTransform: "uppercase", fontSize: "12px" }}
        >
          <img
            src={Icon[el.icon]}
            alt={el.name}
            className="img-fluid"
            style={{ width: "40px" }}
          />
          <span className="ms-3">{el.name}</span>
        </CDBSidebarMenuItem>
      </NavLinkComponent>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        height: "calc(var(--vh, 1vh) * 100)",
        overflow: "scroll initial",
      }}
    >
      {width >= 992 ? (
        <CDBSidebar
          minWidth="10px"
          textColor="#fff"
          backgroundColor={settings.theme.color_sidebar}
        >
          <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>{menuItems}</CDBSidebarMenu>
          </CDBSidebarContent>
        </CDBSidebar>
      ) : null}

      <main className="w-100">
        <Header />

        <Outlet />
        <div style={{ height: "50px" }}>
          <Footer
            handleSetRoute={handleSetRoute}
            setDirection={setDirection}
            direction={direction}
            handleTouchMove={handleTouchMove}
          />
        </div>
      </main>
    </div>
  );
};

export default SideBar;
