import ModalContext from "../../context/Modal/modal.context";
import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import BodyHandle from "../bodyHandle/BodyHandle.component";
const ModalComponent = () => {
  const { isOpen, handleClose } = useContext(ModalContext);
  return (
    <>
      <Modal
        centered
        style={{ overflowY: "scroll" }}
        size="xl"
        show={isOpen}
        onHide={handleClose}
      >
        <Modal.Header style={{border: "none"}} closeButton>
          <div
            className="d-flex align-items-end justify-content-end w-100"
            style={{
              color: "var(--primary_color)",
              fontSize: "2rem",
              fontWeight: "bold",
              cursor: "pointer"
            }}
            onClick={handleClose}
          >
            X
          </div>
        </Modal.Header>
        <BodyHandle />
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ModalComponent;
