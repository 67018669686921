import React, { useContext, useRef } from "react";
import { ArrowDownIcon } from "../../assets/media/icone";
import ModalContext from "../../context/Modal/modal.context";
import StructureProduct from "./StructureProduct.component";
import StructureAzienda from "./StructureAzienda.component";

const BodyHandle = (props) => {
  const ref = useRef(null);
  const {
    modalContent: { image, product },
    /**
     * modalType => 1 PRODOTTO
     * modalType => 2 ALTRO
     */
    modalType
  } = useContext(ModalContext);

  const handleClick = () => {
    ref.current.scroll({ top: 1000, behavior: "smooth" });
  };

  let imgStyle = { backgroundColor: "#fff" }
  let imgProductStyle = { aspectRatio: "1/1", backgroundImage: `url(${image})`, backgroundPosition: 'center', backgroundSize: 'cover' }
  if (modalType !== 1)
    imgStyle = { ...imgStyle, ...imgProductStyle }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 text-center m-auto col-lg-6">
          <div className="row margin-bottom-desktop">
            <div
              className="col-8 m-auto p-3"
              style={{ border: "1px solid var(--primary_color)" }}
            >
              <div className="p-3" style={imgStyle}>
                {modalType === 1 && <img
                  style={{ maxHeight: "30vh" }}
                  src={image}
                  className="img-fluid "
                  alt=""
                />}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 m-auto col-lg-6">
          <div className="row">
            <div
              ref={ref}
              className="col-12 col-lg-8 mt-5 mt-lg-0 px-3 m-auto"
              style={{
                height: "50vh",
                overflowY: "scroll",
                color: "var(--primary_color)",
                overflowX: "hidden"
              }}
            >
              {modalType === 1 ? (
                <StructureProduct product={product} />
              ) : (
                <StructureAzienda info={product} />
              )}
            </div>
            <div
              className="col-12 d-none d-lg-block py-3 text-center btn"
              onClick={handleClick}
            >
              <img alt="arrow_down" src={ArrowDownIcon} style={{ width: "30px" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyHandle;
